import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { mq } from '../utils/helpers';

import {
  Footer,
  HeroImage,
  Layout,
  Boxes,
  HomeText,
} from '../containers';
import {Container, HeroContainer, StyledButton} from "../styled";

class CookiePolicyPageTemplate extends PureComponent {

  constructor(props) {
    super(props);
  }

    getData(key) {
        const { data } = this.props;
        return data.allMarkdownRemark.edges.filter(el => el.node.frontmatter[key])[0].node.frontmatter;
    }

  render() {

      const { contact } = this.getData('contact');

    return (
      <Layout renderWithChat>
          <div style={{marginTop:'6rem'}}></div>

          <React.Fragment>
                  <Container>
                      <p style={{ textAlign: 'center' }}>
                      <strong style={{fontSize:'20px'}}>Polityka prywatności i plików cookies</strong>
                      </p>
                      <p style={{ textAlign: 'center' }}><strong>Informacje og&oacute;lne</strong></p>
                      <p data-style="text-align: left;">Niniejsza Polityka prywatności i plik&oacute;w cookies określa zasady przetwarzania i ochrony danych osobowych oraz wykorzystania plik&oacute;w w związku z korzystaniem przez Użytkownik&oacute;w ze Strony Internetowej.</p>
                      <p style={{ textAlign: 'center', marginBottom:0 }}><strong>&sect; 1</strong></p>
                      <p style={{ textAlign: 'center' }}><strong>Definicje</strong></p>
                      <p data-style="text-align: left;">Na potrzeby niniejszej polityki prywatności przyjmuje się następujące znaczenie poniższych pojęć:</p>
                      <ol data-style="text-align: left;">
                          <li><strong>Administrator</strong> - &nbsp;LINK VISUALS Sp&oacute;łka z ograniczoną odpowiedzialnością z siedzibą w Warszawie przy ul. Hożej 86/410 (00-682 Warszawa), wpisana do Rejestru Przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy dla m. st. Warszawy w Warszawie, XIII Wydział Gospodarczy, pod numerem KRS 0000618576, o numerze NIP: 7010577845, REGON: 364476841, o kapitale zakładowym w wysokości 5.000,00 zł.</li>
                          <li><strong>Strona Internetowa </strong>&ndash; serwis dostępny pod adresem.</li>
                          <li><strong>Użytkownik</strong> - każdy podmiot, kt&oacute;ry przegląda zawartość Strony Internetowej.</li>
                          <li><strong>RODO</strong> - Rozporządzenie Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27 kwietnia 2016 r. w&nbsp;sprawie ochrony os&oacute;b fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.</li>
                      </ol>
                      <p style={{ textAlign: 'center', marginBottom:0 }}><strong>&sect; 2</strong></p>
                      <p style={{ textAlign: 'center' }}><strong>Dane osobowe</strong></p>
                      <ol data-style="text-align: left;">
                          <li>Administratorem danych osobowych Użytkownika w rozumieniu RODO jest Administrator.</li>
                          <li>Podstawą prawną przetwarzania danych osobowych Użytkownik&oacute;w Strony internetowej może być&nbsp; dobrowolna, konkretna, świadoma i jednoznaczna zgoda Użytkownika, obowiązek prawny ciążący na Administratorze wyrażona za pomocą formularza zgłoszeniowego na Stronie Internetowej. Dane osobowe mogą być przetwarzane także w przypadkach, w kt&oacute;rych Administrator jest upoważniony do przetwarzania danych osobowych na podstawie przepis&oacute;w prawa lub na podstawie prawnie uzasadnionego uzasadniony interesu realizowanego realizowany przez Administratora. W szczeg&oacute;lności: <br />[<strong>KONTAKT</strong>]<br />Dane osobowe przekazywane Administratorowi przez Użytkownika w ramach korespondencji emailowej na adresy email podane na Stronie internetowej lub za pośrednictwem form kontaktu dostępnych poprzez profile społecznościowe Administratora przetwarzane są w celu prowadzenia korespondencji (w tym odpowiedzi na zapytania). Podanie danych osobowych przez Użytkownika jest dobrowolne, ale niezbędne, by Użytkownik otrzymał odpowiedź. Przetwarzanie danych w tym celu odbywa się na podstawie prawnie uzasadnionego interesu realizowanego przez administratora (Art.6 ust.1 lit.f RODO). Dane te będą przetwarzane do czasu zakończenia korespondencji oraz 5 lat od jej zakończenia.</li>
                      </ol>
                      <p data-style="text-align: left;">[<strong>WSP&Oacute;ŁPRACA</strong>]<br />Dane osobowe przetwarzane przez Administratora w ramach planowanej lub już nawiązanej wsp&oacute;łpracy przetwarzane będą w celu jej realizacji, a podstawą prawną przetwarzania będzie zawarta umowa. Podanie danych osobowych przez Użytkownika jest dobrowolne, ale niezbędne, by Użytkownik m&oacute;gł zawrzeć umowę z Administratorem. Dane te będą przetwarzane przez czas realizacji wsp&oacute;łpracy oraz realizacji obowiązk&oacute;w ustawowych ciążących na administratorze (np. podatkowo-księgowych), a także na wypadek roszczeń.</p>
                      <ol data-style="text-align: left;" start="3">
                          <li>Administrator może powierzyć dane osobowe innym podmiotom, z pomocą kt&oacute;rych realizuje wskazane&nbsp; w poprzednich punktach cele (np. firmom dostarczającym usługę hostingu, firmie dostarczającej usługę emailingu, etc.)</li>
                          <li>Dane osobowe Użytkownika nie będą przekazywane do odbiorc&oacute;w z państw trzecich ani organizacji międzynarodowych, kt&oacute;re nie zapewniają odpowiedniego stopnia ochrony. Odpowiedni stopień ochrony musi być potwierdzony odpowiednią decyzją Komisji Europejskiej lub innym wiążącym instrumentem prawnym.</li>
                          <li>Administrator gwarantuje poufność wszelkich przekazanych mu danych osobowych.</li>
                          <li>Dane osobowe są gromadzone z należytą starannością i odpowiednio chronione przed dostępem przez osoby do tego nieupoważnione, a ich przetwarzanie odbywa się zgodnie oraz na warunkach określonych szczeg&oacute;łowo w:</li>
                      </ol>
                      <ol data-style="text-align: left;">
                          <li data-style="list-style: none;">
                              <ol data-style="list-style-type: lower-alpha;">
                                  <li>Rozporządzeniu Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27 kwietnia 2016 r. w&nbsp;sprawie ochrony os&oacute;b fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE</li>
                                  <li>i ustawie z dnia 10 maja 2018 o ochronie danych osobowych.</li>
                              </ol>
                          </li>
                      </ol>
                      <p style={{ textAlign: 'center', marginBottom:0 }}><strong>&sect; 3</strong></p>
                      <p style={{ textAlign: 'center' }}><strong>Prawa użytkownika</strong></p>
                      <ol data-style="text-align: left;">
                          <li>Użytkownikowi przysługują prawa związane z przetwarzaniem jego danych osobowych, w&nbsp;tym:</li>
                      </ol>
                      <ol data-style="list-style-type: lower-alpha; text-align: left;">
                          <li>prawo dostępu do danych</li>
                          <li>prawo do poprawienia danych</li>
                          <li>prawo do ograniczenia przetwarzania danych</li>
                          <li>prawo do sprzeciwu&nbsp; wobec przetwarzania danych</li>
                          <li>prawo do usunięcia danych (tzw. prawo do bycia zapomnianym)</li>
                          <li>prawo do przenoszenia danych</li>
                          <li>prawo do wniesienia skargi do organu nadzorczego w związku z przetwarzaniem danych osobowych przez Administratora</li>
                      </ol>
                      <ol data-style="text-align: left;" start="2">
                          <li>Użytkownik ma prawo do cofnięcia swojej zgody w każdej chwili, jeśli Użytkownik uprzednio wyraził taką zgodę.&nbsp;&nbsp;</li>
                          <li>W celu realizacji swoich praw Użytkownik powinien skierować odpowiednie żądanie na adres: Langiewicza 16/1, 02-071 Warszawa.</li>
                      </ol>
                      <p style={{ textAlign: 'center', marginBottom:0 }}><strong>&sect; 4</strong></p>
                      <p style={{ textAlign: 'center' }}><strong>Gromadzone informacje</strong></p>
                      <ol data-style="text-align: left;">
                          <li>Podczas korzystania przez Użytkownika ze Strony internetowej automatycznie zbierane są&nbsp;dane dotyczące Użytkownika. Do tych danych zalicza się m.in.: adres IP, nazwa domeny, typ przeglądarki, typ systemu operacyjnego. Dane te mogą być zbierane przez pliki cookies (tzw.&nbsp;ciasteczka), a także mogą być zapisywane w logach serwera.</li>
                          <li>Pliki cookies (tzw. ciasteczka) to pliki wysyłane do komputera lub innego urządzenia (np.&nbsp;laptop, smartfon, tablet) Użytkownika i tam zapisywane podczas przeglądania Strony internetowej. Pliki&nbsp;cookie zapamiętują preferencje Użytkownika, co umożliwia podnoszenie jakości świadczonych usług, poprawianie wynik&oacute;w wyszukiwania i trafności wyświetlanych informacji oraz anonimowe śledzenie preferencji Użytkownika.</li>
                          <li>Zgodę na przechowywanie lub uzyskiwanie dostępu do cookies przez Administratora na jego urządzeniu Użytkownik wyraża za pomocą ustawień przeglądarki zainstalowanej na urządzeniu Użytkownika.</li>
                          <li>Użytkownik może zrezygnować z cookies, wybierając odpowiednie ustawienia w używanej przez siebie przeglądarce stron www. W takiej sytuacji korzystanie ze Strony internetowej przez Użytkownika może być utrudnione (np. strona może pracować wolniej).</li>
                          <li><a name="_Hlk35929199"></a>Użytkownik może usunąć istniejące pliki cookies z urządzenia, korzystając z odpowiednich funkcji przeglądarki internetowej, program&oacute;w służących w tym celu lub skorzystanie z&nbsp;odpowiednich narzędzi dostępnych w ramach systemu operacyjnego, z kt&oacute;rego korzysta Użytkownik.</li>
                          <li>Dane zapisane w logach serwera lub za pomocą cookies nie są łączone w żaden spos&oacute;b z&nbsp;konkretnymi Użytkownikami&nbsp; Strony internetowej i nie są wykorzystywane przez Administratora w celu identyfikacji Użytkownika. Logi serwera służą do administrowania Stroną internetową, a ich zawartość nie jest ujawniania nikomu poza osobami upoważnionymi do administrowania serwerem.</li>
                          <li>Na Stronie internetowej są stosowane technologie rejestrujące <strong>anonimowo</strong> działania podejmowane przez Użytkownika podczas korzystania ze Strony internetowej. Są to m.in.:
                              <ol data-style="list-style-type: lower-alpha;">
                                  <li>Google Analytics - stosowana w celu analizowania statystyk Strony internetowej</li>
                                  <li>Facebook Pixel - stosowana w celu zarządzania i optymalizacji reklamy na Facebooku <em>jeśli nie korzystają Państwo z Google Analytics, Facebook Pixel proszę usunąć ten punkt</em></li>
                              </ol>
                          </li>
                          <li>Dane uzyskane przez te narzędzia nie są łączone w żaden spos&oacute;b z konkretnymi Użytkownikami Strony internetowej i nie są wykorzystywane przez Administratora w celu identyfikacji Użytkownika. <em>jeśli nie korzystają Państwo z Google Analytics, Facebook Pixel proszę usunąć ten punkt</em></li>
                          <li>Konsekwencją zastosowania technologii wymienionych w &sect; 3 pkt. 7 będzie&nbsp; zoptymalizowanie Strony internetowej, jej treści oraz oferty Produkt&oacute;w do potrzeb Użytkownika. <em>jeśli nie korzystają Państwo z Google Analytics, Facebook Pixel proszę usunąć ten punkt</em></li>
                      </ol>
                      <p style={{ textAlign: 'center', marginBottom:0 }}><strong>&sect; 5</strong></p>
                      <p style={{ textAlign: 'center' }}><strong>Cel wykorzystania danych zapisanych w logach serwera oraz cookies</strong></p>
                      <p data-style="text-align: left;">Dane podane przez Użytkownika lub zbierane automatycznie Administrator wykorzystuje w celu:</p>
                      <ol data-style="text-align: left;">
                          <li data-style="list-style: none;">
                              <ol data-style="list-style-type: lower-alpha;">
                                  <li>prawidłowego funkcjonowania, konfiguracji, bezpieczeństwa Strony Internetowej,</li>
                                  <li>monitorowania stanu sesji,</li>
                                  <li>analiz, badań&nbsp;i audytu wyświetleń Strony Internetowej,</li>
                                  <li>a także w celach statystycznych i marketingowych.</li>
                              </ol>
                          </li>
                      </ol>
                      <p style={{ textAlign: 'center', marginBottom:0 }}><strong>&sect; 6</strong></p>
                      <p style={{ textAlign: 'center' }}><strong>Postanowienia końcowe</strong></p>
                      <ol data-style="text-align: left;">
                          <li>W niniejszym dokumencie mogą nastąpić zmiany, na kt&oacute;re może wpłynąć rozw&oacute;j technologii internetowej, zmiany prawa w zakresie ochrony danych osobowych oraz rozw&oacute;j naszej Strony Internetowej. Wszelkie zmiany będą komunikowane Użytkownikom niezwłocznie w spos&oacute;b widoczny i zrozumiały.</li>
                          <li>Wszelkie pytania lub uwagi dotyczące Polityki prywatności i plik&oacute;w cookies prosimy kierować na adres:&nbsp; Langiewicza 16/1, 02-071 Warszawa</li>
                      </ol>
                  </Container>
          </React.Fragment>

        <Footer contactSectionContent={contact} />
      </Layout>
    );
  }
}

export default CookiePolicyPageTemplate;



export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {  
            contact {
              contact_address_heading
              contact_address_email
              contact_address_website
              contact_address_street
              contact_address_city
              contact_social_heading
              contact_copyright_informations
              contact_cookies_information
              contact_cookies_link
              contact_gdpr_informations
              contact_gdpr_link
            }
          }
        }
      }
    }
  }
`;
